<template>
  <el-form
    ref="form"
    class="I18NLangForm"
    :model="form"
    :rules="isEmptyForm() && lang !== 'en' ? {} : rules"
    size="small"
    label-width="100px"
    label-position="left"
  >
    <el-form-item
      label="名称"
      prop="title"
    >
      <el-input
        v-model="form.title"
        placeholder="单行输入"
      />
    </el-form-item>
    <template v-if="type === '9'">
      <el-form-item
        label="描述"
        prop="desc"
      >
        <el-input
          v-model="form.desc"
          placeholder="单行输入"
          maxlength="40"
          show-word-limit
        />
      </el-form-item>
      <el-form-item
        label="按钮文案"
        prop="btnText"
      >
        <el-input
          v-model="form.btnText"
          placeholder="单行输入"
          maxlength="10"
          show-word-limit
        />
      </el-form-item>
    </template>
    <el-form-item
      label="横图"
      prop="adCover"
    >
      <UploadImage
        :file-list.sync="coverList"
        :vertical="isVertical"
      />
    </el-form-item>
    <el-form-item
      label="竖图"
      prop="adCover2"
    >
      <UploadImage
        :file-list.sync="coverVerticalList"
        :vertical="true"
      />
    </el-form-item>
    <template v-if="type === '16'">
      <el-form-item
        label="游戏介绍"
        prop="desc"
      >
        <el-input
          v-model="form.desc"
          type="textarea"
          placeholder="多行输入"
          maxlength="500"
          show-word-limit
        />
      </el-form-item>
      <el-form-item
        label="游戏ICON"
        prop="gameIcon"
      >
        <UploadImage
          :file-list.sync="gameIconList"
        />
      </el-form-item>
      <el-form-item
        label="游戏视频"
        prop="gameVideo"
      >
        <UploadImage
          :file-list.sync="gameVideoList"
        />
      </el-form-item>
      <el-form-item
        label="游戏图片"
        prop="gameImages"
      >
        <UploadImage
          :file-list.sync="gameImageList"
          :limit="6"
        />
      </el-form-item>
    </template>
  </el-form>
</template>

<script>

export default {
  name: 'I18NLangFormAD',
  props: {
    type: {
      type: String,
      default: '1'
    },
    form: {
      type: Object,
      default: () => {}
    },
    lang: {
      type: String,
      default: 'en'
    },
    ratio: {
      type: Array,
      default: () => []
    },
    ratioMax: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      rules: {
        // desc: [{ required: true, message: '广告描述必填' }],
        // btnText: [{ required: true, message: '按钮文案必填' }],
        // title: [{ required: true, message: '广告名称必填' }]
      },
      coverList: [],
      coverVerticalList: [],
      gameIconList: [],
      gameVideoList: [],
      gameImageList: []
    }
  },
  computed: {
    isVertical () {
      if (this.ratio && this.ratio.length) {
        const [width, height] = this.ratio
        return width < height
      }
      return false
    }
  },

  created () {
    const unwatch = this.$watch(
      'form',
      function (form) {
        // console.log(form)
        if (!form) return

        const prefix = 'https://cdn.bifrost.com/' // 在这里替换为你想要添加的前缀

        if (form.image) {
          this.coverList.push({ url: form.image, key: form.image })
          this.coverVerticalList.push({ url: form.image_vertical, key: form.image_vertical })
          if (unwatch) { unwatch() }
        }

        if (form.game_icon) {
          this.gameIconList.push({ url: prefix + form.game_icon, key: form.game_icon })
        }

        if (form.game_video) {
          this.gameVideoList.push({ url: prefix + form.game_video, key: form.game_video, extend: 'mp4' })
        }

        if (form.game_images) {
          this.gameImageList = form.game_images.map((item) => {
            return { url: prefix + item, key: item }
          })
        }
      },
      { immediate: true }
    )
  },
  methods: {
    updateForm ({ image, gameName, imageVertical }) {
      // const prefix = 'https://cdn.bifrost.com/' // 在这里替换为你想要添加的前缀

      // this.coverList = [{ url: prefix + image, key: image }]
      // this.coverVerticalList = [{ url: prefix + image_vertical, key: image_vertical }]
      // this.$set(this.form, 'title', gameName)
      this.coverList = [{ url: image, key: image }]
      this.coverVerticalList = [{ url: imageVertical, key: imageVertical }]
      console.log(this.coverList, 'this.coverList')
      console.log(this.coverVerticalList, 'this.coverVerticalList')
      this.$set(this.form, 'title', gameName)
    },
    async validate () {
      return this.$refs.form.validate().then(valid => {
        if (valid) {
          if (this.coverList.length) {
            this.form.image = this.coverList[0].key
          }
          if (this.coverVerticalList.length) {
            this.form.image_vertical = this.coverVerticalList[0].key
          }
          if (this.gameIconList.length) {
            this.form.game_icon = this.gameIconList[0].key
          }
          if (this.gameVideoList.length) {
            this.form.game_video = this.gameVideoList[0].key
          }
          if (this.gameImageList.length) {
            this.form.game_images = this.gameImageList.map((item) => item.key)
          }
          this.form.lang = this.lang
          return Promise.resolve()
        }
      })
    },

    isEmptyForm () {
      let isEmpty = true
      Object.keys(this.form).forEach(field => {
        if (this.form[field]) isEmpty = false
      })
      if (isEmpty && this.$refs.form) this.$refs.form.clearValidate()
      return isEmpty
    }
  }
}
</script>
<style scoped lang="less">
  .I18NLangForm{
    .el-input,
    .el-textarea {
      width: 420px;
    }
  }
</style>

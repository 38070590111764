<template>
  <Layout class="AdvertisementEdit">
    <div class="content">
      <h4 class="title">
        信息
      </h4>
      <el-form
        ref="form"
        v-loading="fetchLoading"
        :model="formData"
        :rules="rules"
        label-width="110px"
        label-position="left"
      >
        <el-form-item
          label="排序"
          prop="sort"
        >
          <el-input-number
            v-model="formData.sort"
            :min="0"
            clearable
          />
        </el-form-item>
        <el-form-item label="所属板块">
          <el-select
            v-model="formData.pid"
            disabled
          >
            <el-option
              v-for="(item, index) in positionList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="描述"
          prop="desc"
        >
          <el-input
            v-model="formData.desc"
            type="textarea"
            clearable
            placeholder="多行描述"
            maxlength="40"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label="Tag"
          prop="tag"
        >
          <el-select
            v-model="formData.tag"
            placeholder="请选择展示类型"
            clearable
            @change="changeTag"
          >
            <el-option
              v-for="(item, index) in advertisementTagList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="formData.tag === 1"
          label="URL"
          prop="url"
        >
          <el-input
            v-model="formData.url"
            clearable
            placeholder="单行输入"
          />
        </el-form-item>
        <el-form-item
          v-if="formData.tag === 2 || formData.tag === 6"
          label="关联游戏"
          prop="link_game"
        >
          <el-select
            v-model="formData.link_game"
            value-key="gameId"
            style="width: 420px;"
            filterable
            remote
            clearable
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="$refs.subForm[0].updateForm(formData.link_game)"
          >
            <el-option
              v-for="item in options"
              :key="item.gameId"
              :label="item.gameName"
              :value="item"
              :disabled="item.gameStatus === 2"
            >
              <span style="float: left">{{ item.gameName }}</span>
              <span
                v-if="item.gameStatus === 2"
                style="float: right; color: red;"
              >
                已下架
              </span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="面向国家"
          prop="region_type"
        >
          <el-select
            v-model="formData.settings.region_type"
            @change="clearRegionCode()"
          >
            <el-option
              v-for="(regionTypeitem, regionTypekey) in regionTypeObj"
              :key="regionTypekey"
              :label="regionTypeitem "
              :value="regionTypekey"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="formData.settings.region_type !== 'all'"
          label="国家列表"
          prop="region_code"
        >
          <el-input
            v-model="formData.settings.region_code"
          />
          <span style="color:red">
            多个国家使用” , “号隔开
          </span>
        </el-form-item>
        <!-- 新添加 -->
        <el-form-item
          label="面向语言"
          prop="language_type"
        >
          <el-select
            v-model="formData.settings.language_type"
            @change="clearlanguageCode()"
          >
            <el-option
              v-for="(languageTypeitem, languageTypekey) in languageTypeObj"
              :key="languageTypekey"
              :label="languageTypeitem "
              :value="languageTypekey"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="formData.settings.language_type != 'all'"
          label="语言配置"
          prop="language_code"
        >
          <el-input
            v-model="formData.settings.language_code"
          />
          <span style="color:red">
            多个语言使用” , “号隔开，语言代码为小写字母
          </span>
        </el-form-item>
        <el-form-item
          label="外显版本"
          prop="version"
        >
          <el-input
            v-model.number="formData.settings.version[0]"
            style="width:91px"
          />
          <span style="padding: 0 6px">-</span>
          <el-input
            v-model.number="formData.settings.version[1]"
            style="width:91px"
          />
        </el-form-item>
        <el-alert
          v-if="formData.tag === 6"
          title="所选 Tag 为'游戏试玩详情页', 请配置'可试玩'的游戏"
          type="warning"
          :closable="false"
          style="width: 530px;"
        />
        <el-form-item
          v-if="formData.tag === 4"
          label="按钮跳转连接"
          prop="btn_link"
        >
          <el-input
            v-model="formData.btn_link"
            clearable
            placeholder="单行输入"
          />
        </el-form-item>

        <el-form-item
          v-if="formData.tag === 5"
          label="应用包名"
          prop="google_link"
        >
          <el-input
            v-model="formData.google_link"
            clearable
            placeholder="单行输入"
          />
        </el-form-item>

        <el-form-item
          v-if="formData.tag === 8"
          label="广告位id"
          prop="ad_source"
        >
          <el-input
            v-model="formData.ad_source"
            clearable
            placeholder="单行输入"
          />
        </el-form-item>
        <el-form-item
          v-if="formData.tag === 9"
          label="广告位id"
          prop="ad_source"
        >
          <el-input
            v-model="formData.ad_source"
            clearable
            placeholder="单行输入"
          />
        </el-form-item>
        <!-- <el-form-item
          label="图"
          prop="adCover"
        >
          <UploadImage
            :file-list.sync="coverList"
          />
        </el-form-item> -->
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-switch
            v-model="formData.status"
            :active-value="1"
            :inactive-value="2"
            active-text="启用"
            inactive-text="禁用"
          />
        </el-form-item>
        <template v-if="style === '9'">
          <el-form-item
            label="定向区域"
            prop="showNations"
          >
            <div class="setting-nations">
              <el-button
                type="text"
                @click="handleShowSelectNation"
              >
                编辑国家
              </el-button>
            </div>
          </el-form-item>
          <section
            style="width: 720px;"
            class="nationList"
            :class="{
              open: showAllNation
            }"
          >
            <el-table :data="formData.showNations">
              <el-table-column
                label="国家/地区"
                prop="nation"
                min-width="200"
              />
              <el-table-column
                label="状态"
                width="120"
              >
                展示
              </el-table-column>
            </el-table>
          </section>
        </template>
        <div
          v-if="formData.showNations.length > 3"
          class="show-more"
        >
          <span
            v-if="!showAllNation"
            @click="showAllNation = true"
          >点击展开 <i class="el-icon-arrow-down" /></span>

          <span
            v-else
            @click="showAllNation = false"
          >点击收起 <i class="el-icon-arrow-up" /></span>
        </div>
      </el-form>

      <h4 class="title title-2">
        多语言配置
      </h4>
      <el-tabs
        v-model="currentLang"
        type="card"
      >
        <el-tab-pane
          v-for="(item, index) in langList"
          :key="index"
          :label="item.name"
          :name="item.value"
        >
          <I18NLangFormAD
            ref="subForm"
            :key="index"
            :type="style"
            :lang="item.value"
            :form="I18NFieldMap[item.value]"
            :ratio="ratio"
            :ratio-max="ratioMax"
            @validateFail="handleValidateFail"
          />
        </el-tab-pane>
      </el-tabs>
      <div class="ctrl-area">
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleSubmit"
        >
          保存
        </el-button>
        <el-button @click="handleCancel">
          取消
        </el-button>
      </div>
    </div>

    <el-drawer
      title="编辑国家"
      :visible.sync="showSelectNation"
      direction="rtl"
      @close="handleSelectClose"
    >
      <el-table
        ref="multipleTable"
        :data="nationList"
        style="width: 100%"
        height="calc(100vh - 200px)"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <el-table-column
          label="国家/地区"
          prop="nation"
          min-width="120"
        />
      </el-table>
      <div class="select-area">
        <span>选择要定向的国家</span>
        <div>
          <el-button
            type="text"
            @click="handleCancelSelect"
          >
            取消
          </el-button>
          <el-button
            type="primary"
            plain
            @click="handleConfirmSelect"
          >
            确定
          </el-button>
        </div>
      </div>
    </el-drawer>
  </Layout>
</template>

<script>
import { advertisementTagList, langList } from '@/utils/selectOptions'
import { searchGameList } from '@/api/gameList'
import { cloneDeep } from 'lodash'
import { queryADInfo, UpdateActivityResource, CreateActivityResource } from '@/api/APP_Category'
import { mapState, mapActions, mapGetters } from 'vuex'
import I18NLangFormAD from './components/I18NLangFormAD'

import {
  getUserNationStatistics
} from '@/api/electronicEntity.js'

const initLangForm = () => {
  return langList.reduce((maps, item) => {
    maps[item.value] = {}
    return maps
  }, {})
}
const regionTypeObj = {
  all: '全部国家',
  display: '部分国家显示',
  hide: '部分国家隐藏'
}
const languageTypeObj = {
  all: '全部语言',
  display: '部分语言显示',
  hide: '部分语言隐藏'
}

export default {
  name: 'ADEdit',
  components: { I18NLangFormAD },
  data () {
    const showNationsValidator = (rule, value, callback) => {
      if (this.formData.showNations.length) {
        return callback()
      } else {
        callback(new Error('至少选择一个国家'))
      }
    }
    return {
      regionTypeObj,
      languageTypeObj,
      advertisementTagList,
      langList,
      positionList: [],
      isADEdit: true,
      options: [],
      coverList: [],
      loading: false,
      adKey: '',
      isCreated: '',
      formData: {
        status: 2,
        desc: '',
        showNations: [],
        settings: {
          region_type: 'all',
          region_code: '',
          language_type: 'all',
          language_code: '',
          version: [
            '',
            ''
          ]
        }
      },
      updateLoading: false,
      rules: {
        title: [{ required: true }],
        sort: [{ required: true }],
        link_game: [{ required: true }],
        tag: [{ required: true }],
        url: [{ required: true }],
        btn_link: [{ required: true }],
        google_link: [{ required: true }],
        ad_source: [{ required: true }],
        showNations: [{ required: true, validator: showNationsValidator }]
      },
      appModuleListInfo: {
        list: []
      },
      childForminfo: {},
      fetchLoading: false,
      currentLang: 'en',
      I18NFieldMap: initLangForm(),
      style: '',
      nationList: [],
      showSelectNation: false,
      tempSelectionRows: [],
      showAllNation: false
    }
  },

  computed: {
    ...mapState('app', ['appId', 'channel']),
    ...mapGetters('selectOptions', ['modelStyleListMap']),
    ratio () {
      return this.modelStyleListMap?.[this.style]?.ratio || []
    },
    ratioMax () {
      return this.modelStyleListMap?.[this.style]?.ratioMax || []
    }
  },
  created () {
    this.isADEdit = this.$route.name === 'ADEdit'
    this.style = this.$route.query.style + ''
    this.formData.pid = Number(this.$route.query.pId)
    this.formData.zoneId = Number(this.$route.query.zoneId)
    this.positionList = [
      { name: this.$route.query.name || 'Error', value: Number(this.$route.query.pId) }
    ]
    if (this.isADEdit) {
      this.handleQueryADInfo()
    }
    this.handleSearchNation()
    this.queryModelStyleList()
  },

  methods: {
    ...mapActions('selectOptions', ['queryModelStyleList']),
    // 在选择国家all的时候，内容清空
    clearRegionCode () {
      if (this.formData.settings.region_type === 'all') {
        this.formData.settings.region_code = ''
      }
    },
    // 在选择语言all的时候，内容清空
    clearlanguageCode () {
      if (this.formData.settings.language_type === 'all') {
        this.formData.settings.language_code = ''
      }
    },
    // 选择tag === 2 ||  === 6
    changeTag () {
      if (this.formData.tag === 2 || this.formData.tag === 6) {
        this.formData.link_game = this.formData.game_doc.game_name
      }
    },
    handleSearchNation () {
      getUserNationStatistics({
        searchWord: '',
        pageNum: 1,
        pageSize: 1000
      }).then(res => {
        if (res.code === 200) {
          this.nationList = res.data.list.map(item => {
            const { countryCode, id, nation } = item
            return { countryCode, id, nation }
          })
        }
      })
    },
    handleQueryADInfo () {
      const id = this.$route.query.id
      this.fetchLoading = true
      queryADInfo(
        id)
        .then(res => {
          // if (res.code === 200) {
          this.formData = res
          console.log(res, 'res')

          this.formData.zoneId = Number(this.$route.query.zoneId)
          this.formData.pid = Number(this.$route.query.pId)
          // const { gameIdString: gameId, gameName, platform, tips, startMode, rentalSupport, gameStatus } = this.formData
          // this.formData.link_game = { gameId, gameName, platform, tips, startMode, rentalSupport }
          // this.options = [{ gameId, gameName, platform, tips, startMode, rentalSupport, gameStatus }]
          console.log(this.formData.link_game, 'link_game')
          // if (this.formData.tag === 2 || this.formData.tag === 6) {
          //   this.formData.link_game = res.game_doc.game_name
          // }
          if (res.game_doc && (this.formData.tag === 2 || this.formData.tag === 6)) {
            this.formData.link_game = res.game_doc.game_name
          } else {
            this.formData.link_game = '' // 或者其他适当的值
          }
          if (Array.isArray(this.formData.settings.version) && this.formData.settings.version.length === 0) {
            this.formData.settings.version = ['', '']
          }
          this.I18NFieldMap = this.formData.languagesObject
          this.formData.showNations = JSON.parse(this.formData.showNations || '{}')

          const prefix = 'https://cdn.bifrost.com/' // 在这里替换为你想要添加的前缀
          this.formData.languagesObject.en.image = prefix + this.formData.languagesObject.en.image || ''
          this.formData.languagesObject.en.image_vertical = prefix + this.formData.languagesObject.en.image_vertical || ''
          console.log(this.formData.languagesObject.en.image_vertical, 'this.formData.languagesObject.en.imageVertical')

          this.langList.forEach(item => {
            if (!this.I18NFieldMap[item.value]) {
              this.$set(this.I18NFieldMap, item.value, {})
            }
          })
        })
        .finally(() => {
          this.fetchLoading = false
        })
    },
    remoteMethod (query) {
      if (!query) return
      this.loading = true
      console.log(query, 'query------------')
      searchGameList({ keyWord: query, pageNum: 1, pageSize: 30 })
        .then(res => {
          if (res.code === 200) {
            console.log(res)
            this.options = res.data.list.map(item => {
              return {
                gameId: item.gameIdStr,
                gameName: item.gameName,
                imageVertical: item.imageVertical,
                image: item.image,
                tips: item.tag,
                platform: item.platform,
                startMode: item.startMode,
                rentalSupport: item.rentalSupport,
                gameStatus: item.GameStatus
              }
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleCheckAllForm () {
      const checkAll = (resolve) => {
        Promise.all(this.$refs.subForm.map(item => item.validate()))
          .then((valid) => {
            if (valid) {
              resolve()
            } else {
              this.$message.error('校验未通过, 请检查表单后再试')
            }
          }).catch(err => {
            console.log(err)
          })
      }
      return new Promise(resolve => {
        this.$refs.form.validate()
          .then(valid => {
            if (valid) {
              checkAll(resolve)
            } else {
              this.$message.error('校验未通过, 请检查表单后再试')
            }
          })
      })
    },
    handleSubmit () {
      // const that = this
      const id = this.$route.query.id
      this.handleCheckAllForm()
        .then(() => {
          this.updateLoading = true
          const formData = this.preFormatFormData()
          // if (that.$refs.subForm[0].coverList[0] && that.$refs.subForm[0].coverList[0].url) {
          //   formData.languagesObject.en.image = that.$refs.subForm[0].coverList[0].url
          // } else {
          //   formData.languagesObject.en.image = ''
          // }
          // if (that.$refs.subForm[0].coverVerticalList[0] && that.$refs.subForm[0].coverVerticalList[0].url) {
          //   formData.languagesObject.en.imageVertical = that.$refs.subForm[0].coverVerticalList[0].url
          // } else {
          //   formData.languagesObject.en.imageVertical = ''
          // }
          console.log(formData.languagesObject.en.image, 'sd')

          this.formData.languagesObject = JSON.parse(JSON.stringify(formData.languagesObject))

          // 移除前缀
          if (this.formData.languagesObject.en.image) {
            this.formData.languagesObject.en.image = this.formData.languagesObject.en.image.replace('https://cdn.bifrost.com/', '')
          }
          if (this.formData.languagesObject.en.image_vertical) {
            this.formData.languagesObject.en.image_vertical = this.formData.languagesObject.en.image_vertical.replace('https://cdn.bifrost.com/', '')
          }
          if (this.formData.tag === 2 || this.formData.tag === 6) {
            this.formData.link_game = this.formData.link_game.gameId
          } else {
            this.formData.link_game = 0
          }
          if (this.formData.settings.language_type === 'all') {
            this.formData.settings.language_code = ''
          }
          if (this.formData.settings.region_type === 'all') {
            this.formData.settings.region_code = ''
          }
          if (this.formData.settings.version === []) {
            this.formData.settings.version = ['', '']
          }
          console.log(this.formData.languagesObject, '--------')
          if (id) {
            UpdateActivityResource(id, this.formData)
            this.$message.success('更新成功')
            this.$router.go(-1)
          } else {
            CreateActivityResource(this.formData)
            this.$message.success('添加成功')
            this.$router.go(-1)
          }
        })
    },
    handleCancel () {
      this.$router.back()
    },
    preFormatFormData () {
      const formData = cloneDeep(this.formData)
      formData.languagesObject = this.I18NFieldMap
      const game = formData.link_game
      console.log(game, 'game')
      Object.assign(formData, game)
      formData.showNations = JSON.stringify(formData.showNations)
      return formData
    },

    // 校验同级表单时, 切换到第一个报错的表单项
    handleValidateFail (lang) {
      if (this.isValidateFail) return
      this.isValidateFail = true
      setTimeout(() => {
        this.isValidateFail = false
        this.currentLang = lang
      })
    },
    handleShowSelectNation () {
      this.showSelectNation = true
      const selectionIdList = this.formData.showNations.map(item => item.id)
      const selectionList = this.nationList.filter(item => {
        return selectionIdList.includes(item.id)
      })
      this.$nextTick(() => {
        selectionList.forEach(item => {
          this.$refs.multipleTable.toggleRowSelection(item, true)
        })
      })
    },
    handleSelectClose () {
      this.$refs.multipleTable.clearSelection()
    },
    handleSelectionChange (selection) {
      this.tempSelectionRows = selection
    },
    handleCancelSelect () {
      this.showSelectNation = false
    },
    handleConfirmSelect () {
      this.formData.showNations = this.tempSelectionRows
      this.showSelectNation = false
    }
  }

}
</script>
<style lang="less">

  .AdvertisementEdit{
    .el-input-number--medium{
      width: 217px;
    }
  }
</style>
<style scoped lang="less">
  .AdvertisementEdit{
    .content{
      /*width: 500px;*/
      margin: 50px 80px;
    }
  }
  .nationList {
    height: 180px;
    overflow: hidden;
    &.open {
      height: auto;
      overflow: auto;
    }
  }
  .show-more {
    width: 780px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-size: 14px;
    color: #aaa;
    >span {
      cursor: pointer;
    }
  }
  .setting-nations {
    width: 600px;
    display: flex;
    justify-content: flex-end;
  }
  .select-area {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  h4.title{
    margin-bottom: 50px;
  }
  .el-input,
  .el-textarea{
    width: 420px;
  }
  .ctrl-area{
    margin-top: 80px;
    .el-button{
      padding:10px  40px;
    }
  }
</style>
